window._ = require('lodash');

window.$ = window.jQuery = require('jquery');
window.moment = require('moment');
require('moment-timezone');
//require('../../../bower_components/jquery-ui/jquery-ui.min.js');
require('bootstrap-sass');
require('angular');
require('angular-animate');
require('angular-loading-bar');
require('angular-cookies');
require('angular-sanitize');
require('angular-ui-bootstrap');
require('angular-drag-and-drop-lists');
require('angular-filter');
require('angular-css');
require('angular-ui-mask');
require('../../../bower_components/angular-ui-utils/ui-utils.min.js');
require('../../../bower_components/ngMask/dist/ngMask.min');
require('angular-summernote/dist/angular-summernote.min');
require('../../../bower_components/angular-svg-round-progressbar');
require('angular-ui-sortable');
require('../../../bower_components/summernote/dist/summernote.min');
require('ngstorage');
require('ng-idle');
require('ng-toast');
require('ngclipboard');
require('ui-select');
require('@uirouter/angularjs');
require('angular-uuid');

// Plugins
window.StackTrace = require('stacktrace-js');
window.ErrorStackParser = require('error-stack-parser');
require('../../../public/js/plugins/jquery-autonumeric/autoNumeric.js');
require('../../../public/js/plugins/ng-switchery/ng-switchery.js');
window.Switchery = require('../../../public/js/plugins/switchery/js/switchery');
// window.Dropzone.autoDiscover = false;
require('../../../public/js/plugins/ng-dropzone/ng-dropzone.js');
window.Dropzone = Dropzone = require('dropzone/dist/dropzone');
window.Highcharts = require('highcharts');
require('../../../public/js/plugins/d3/d3.min.js');
require('../../../public/js/plugins/nvd3/nv.d3.min.js');
require('../../../public/js/plugins/angular-nvd3/angular-nvd3.min.js');
require('../../../public/js/plugins/angular-bootstrap-colorpicker/js/bootstrap-colorpicker.min.js');
require('ng-file-upload');
require('angularjs-slider');
window.AutoNumeric = require('autonumeric/dist/autoNumeric.min.js');

window.axios = require('axios');

window.axios.defaults.headers.common = {
	'X-CSRF-TOKEN': window.csrfToken,
	'X-Requested-With': 'XMLHttpRequest'
};